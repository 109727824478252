/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function truncate(str, limit = 450, trailing = '...', sep = ' ') {
  if (!str || str.length <= limit) {
    return str;
  }
    // TODO (bmcginnis): split punctuation too so we don't end up with "foo,..."
  const words = str.split(sep);
  const max = limit - trailing.length;
  let truncated = '';

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (truncated.length + word.length > max ||
      (i !== 0 && truncated.length + word.length + sep.length > max)) {
      truncated += trailing;
      break;
    }

    truncated += i === 0 ? word : sep + word;
  }

  return truncated;
}